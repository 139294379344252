<template>
  <Top1></Top1>
  <DSwiper></DSwiper>
  <DloadList1></DloadList1>
</template>

<script setup>

import { reactive, nextTick } from 'vue'

import DSwiper from '@/comp/DSwiper.vue'
import DloadList1 from '@/comp/DloadList1.vue'
import Top1 from '@/comp/Top1.vue'

const state = reactive({
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.titleCls {
  text-align: center;
  background-color: #ff0000;
  width: 70px;
  padding: 0px 10px;
  height: 100%;
}
</style>
