<template>
  <div
    style="display:flex; flex-direction:column; align-items:center; width:100%; height:850px; background: linear-gradient(180deg, #fff0f0, #ffffff);">
    <img style="margin-top:50px; width:300px; height:auto;" src="@/assets/img/kfhhck.png">
    <img style="margin-top:20px; width:50px; height:auto;" src="@/assets/img/xjt.png">

    <div style="margin-top:50px; display:flex; ">
      <div style="width:200px;display:flex; justify-content: center;">
        <img style="width:40px; height:auto;" src="@/assets/img/zfj.png">
        <p style="margin-left:10px; line-height:20px;">Telegram：<br>xc9188</p>
      </div>
      <div style="width:200px;display:flex; justify-content: center;">
        <img style="width:40px; height:auto;" src="@/assets/img/ym.png">
        <p style="margin-left:10px; line-height:20px;">易记域名：<br>www.8SG.com</p>
      </div>
    </div>

    <p style="margin-top:30px; font-size:12px;">温馨提示：以上为官方唯一联系方式，其余皆为作假！</p>

    <img style="margin-top:50px; width:200px; height:auto;" src="@/assets/img/yjtdx.png">
    <div style="position:relative">
      <img style="margin-top:50px; width:500px; height:auto;" src="@/assets/img/yjx.png">
      <div @click="click" style="position: absolute; top:80px; right:50px; width:70px; height:70px;" class="button">
      </div>
      <input @change="changeTitle" :value="state.title"
        style="padding-left:5px; font-size:14px; border-radius:5px; border:0px; height:20px; width:184px; position:absolute; top:68px; left:170px;"
        placeholder="请输入标题" />
      <input @change="changeEmail" :value="state.email"
        style="padding-left:5px; font-size:14px; border-radius:5px; border:0px; height:20px; width:184px; position:absolute; top:96px; left:170px;"
        placeholder="请输入email" />
      <textarea @change="changeContent" :value="state.content"
        style="resize:none; padding-left:5px; font-size:14px; border-radius:5px; border:0px; height:55px; width:184px; position:absolute; top:124px; left:170px;"
        placeholder="请输入具体内容" />
    </div>

  </div>
</template>

<script setup>

import { reactive, nextTick } from 'vue'

import axios from 'axios'
import { showToast, showFailToast, showConfirmDialog } from 'vant';

const state = reactive({
  title: '',
  email: '',
  content: '',
})

const changeTitle = e => {
  state.title = e.target.value;
}

const changeEmail = e => {
  state.email = e.target.value;
}

const changeContent = e => {
  state.content = e.target.value;
}

const click = () => {
  // alert('确定提交');

  if (!state.title) return showFailToast('意见标题不能为空');
  if (!state.email) return showFailToast('邮箱地址不能为空');
  if (!state.content) return showFailToast('意见内容不能为空');

  var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  if (!emailRegex.test(state.email)) return showFailToast('请输入正确的邮箱地址');

  let p = { content: state.content, email: state.email };
  axios.post(window['url'] + 'home/slides/setComments_gw', p).then(res => {
    if (res.data.code == 1) {
      //请求成功
      showToast(res.data.msg);

      state.title = '';
      state.content = '';
    }
  }).catch(() => { })

}

nextTick(() => {
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button {
  background-size: cover;
  background-image: url(@/assets/img/1.png);
  cursor: pointer;
  border: none;
}

.button:hover {
  background-size: cover;
  background-image: url(@/assets/img/2.png);
}
</style>
