<template>
  <div>
    <Top :selSel="selSel"></Top>
    <DSwiper v-if="state.sel == 1"></DSwiper>
    <DloadList v-if="state.sel == 1"></DloadList>
    <Prize v-if="state.sel == 2"></Prize>
    <Contact v-if="state.sel == 3"></Contact>
    <Bottom></Bottom>
  </div>
</template>

<script setup>

import { reactive, nextTick } from 'vue'

import Top from '@/comp/Top.vue'
import DloadList from '@/comp/DloadList.vue'
import DSwiper from '@/comp/DSwiper.vue'
import Prize from '@/comp/Prize.vue'
import Contact from '@/comp/Contact.vue'
import Bottom from '@/comp/Bottom.vue'

const state = reactive({
  sel: 1,
})

const selSel = (o) => {
  state.sel = o;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
