<template>
  <div>
    <div
      style="border-bottom:1px solid #aaa; display:flex; align-items:center; justify-content:space-around;height:80px; background-color: #fff;">
      <div style="display:flex; justify-content: center; align-items: center; ">
        <img style="margin-left:20px; width:50px; height:50px;" src="@/assets/img/logo.png">
        <div style="margin-left:10px; display:flex; justify-content: center; align-items: left; flex-direction:column;">
          <p v-if="state.innerWidth > 600"
            style="font-family:Microsoft Yahei; font-weight:800; color:#333333; font-size:20px;">搜奇选妙</p>
          <p v-if="state.innerWidth > 730" style="color:#2f2f2f; font-size:12px; margin-top:10px; ">彩市最强大的彩票分析软件</p>
        </div>

      </div>
      <div
        style="font-size:14px; position:relative; display:flex;justify-content:space-around; height:100%; align-items:center;">
        <div @click="click(1)" :class="state.sel == 1 ? 'tabSelCls' : 'tabSelCls1'" class="tabCls">
          <p>首页</p>
          <p v-if="state.sel == 1" style="margin-top:10px;">Home</p>
        </div>
        <div @click="click(2)" :class="state.sel == 2 ? 'tabSelCls' : 'tabSelCls1'" class="tabCls">
          <p>大奖查询</p>
          <p v-if="state.sel == 2" style="text-align:center; margin-top:10px;">Prize Query</p>
        </div>
        <div @click="click(3)" :class="state.sel == 3 ? 'tabSelCls' : 'tabSelCls1'" class="tabCls">
          <p>联系我们</p>
          <p v-if="state.sel == 3" style="margin-top:10px;">Contact Us</p>
        </div>
        <div class="tabCls1" @click="collect">
          <img style="margin-right:5px; width:20px; height:20px;" src="@/assets/img/sc1.png">
          收藏网址
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import { reactive, nextTick } from 'vue'
import { showSuccessToast } from 'vant'
import { onMounted, onUnmounted } from 'vue';

const props = defineProps({
  selSel: { type: Function, default: null },
  // sel: { type: Number, default: 1 },
})

const state = reactive({
  sel: 1,
  innerWidth: 0,
})

const click = sel => {
  state.sel = sel;
  props.selSel(sel);
}

function AddFavorite(sURL, sTitle) {
  try {
    var ctrl = navigator.userAgent;
    ctrl = ctrl.toLocaleLowerCase();
    if (document.all || ctrl.match(/msie/) != null || ctrl.match(/trident/) != null) {   //IE 类 浏览器
      window.external.addFavorite(sURL, sTitle);
    } else {
      if (window.sidebar) {   //firfox等浏览器
        window.sidebar.addPanel(sTitle, sURL, "");
      } else {
        alert("加入收藏失败，请使用Ctrl+D进行添加")
      }
    }
  } catch (e) {
    alert("加入收藏失败，请使用Ctrl+D进行添加");
  }
}

const collect = () => {

  let sTitle = '搜奇选妙';
  let sURL = 'https://souqixuanmiao.com';

  try {
    var ctrl = navigator.userAgent;
    ctrl = ctrl.toLocaleLowerCase();
    if (document.all || ctrl.match(/msie/) != null || ctrl.match(/trident/) != null) {   //IE 类 浏览器
      window.external.addFavorite(sURL, sTitle);
    } else {
      if (window.sidebar) {   //firfox等浏览器
        window.sidebar.addPanel(sTitle, sURL, "");
        showSuccessToast({ message: '收藏成功' })
      } else {
        alert("加入收藏失败，请使用Ctrl+D进行添加")
      }
    }
  } catch (e) {
    alert("加入收藏失败，请使用Ctrl+D进行添加");
  }

  // showSuccessToast({ message: '收藏成功' })
}

const handleResize = () => {
  state.innerWidth = window.innerWidth;
}

onMounted(() => {
  window.addEventListener('resize', handleResize);
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
})

nextTick(() => {
  state.innerWidth = window.innerWidth;
})

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tabCls {
  width: 80px;
  margin: 0px 10px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tabCls1 {
  width: 120px;
  margin: 0px 10px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.tabSelCls {
  background: linear-gradient(90deg, #fe7e67, #d92733);
  color: #fff;
}

.tabSelCls1 {
  background: #fff;
  color: #333;
}
</style>
