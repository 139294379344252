<template>
  <div style="width:100%; height:160px; background-color:#FDDDDD">
    <div style="height:100%; display:flex; justify-content: center; ">
      <div
        style="color:#7f2929; height:100%; display:flex; justify-content: center; flex-direction: column; align-items: center;">
        <p style="border-radius:5px; margin-top:-10px; padding:10px 40px; background:#ffbead; width:360px;">
          中国已禁止网络购彩，如有需求请前往福彩线下实体店。</p>
        <p style="border-radius:5px; margin-top:10px; padding:10px 40px; background:#ffbead; width:360px;">
          官网域名：souqixuanmiao.com csbd8.com csbd6.com</p>
        <p style="margin-top:10px; ">Copyright©2018-选妙科技 ALL Right Reserved</p>
      </div>
      <img style="margin-top:20px; width:130px; height:100px;" src="@/assets/img/rz.png">
    </div>

  </div>

</template>

<script setup>

import { reactive, nextTick } from 'vue'

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';

const state = reactive({
  isMobile: false,
})

nextTick(() => {
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dloadCls {
  padding: 4px 10px;
  border: 1px solid #ff634b;
  text-align: center;
  margin-top: 20px;
  border-radius: 20px;
  color: #ff634b;
}

.selCls {
  padding: 4px 10px;
  text-align: center;
  margin-top: 15px;
}
</style>
