<template>
  <img style="width:100%; height:auto;" src="@/assets/img/bannerx.png">
</template>

<script setup>

import { reactive, nextTick } from 'vue'

const props = defineProps({
  selSel: { type: Function, default: null },
  // sel: { type: Number, default: 1 },
})

const state = reactive({
  sel: 1,
})

const click = sel => {
  state.sel = sel;
  props.selSel(sel);
}

nextTick(() => {
})

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tabCls {
  width: 70px;
  margin: 0px 10px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.tabSelCls {
  background: linear-gradient(90deg, #fe7e67, #d92733);
  color: #fff;
}
</style>
