<template>

  <div>
    <div style="width:100%; background-color: #f5f5f5;">
      <van-tabs class="tabCls" v-model:active="state.selTabIndex" swipeable animated>
        <van-tab :title="item.name" v-for="(item, index) in caiList" :key="item.id">
          <div
            style="border-top:1px solid #d3d3d3; display:flex; background: linear-gradient(180deg, #FFF1F1, #ffffff);">
            <div style="width:40%; padding:20px; display:flex; justify-content:right; ">
              <img v-if="index == 0" style="max-width:300px; width:100%; height:fit-content;" src="@/assets/img/kkk0.png">
              <img v-else-if="index == 1" style="max-width:300px; width:100%; height:fit-content;" src="@/assets/img/kkk1.png">
              <img v-else-if="index == 2" style="max-width:300px; width:100%; height:fit-content;" src="@/assets/img/kkk2.png">
              <img v-else-if="index == 3" style="max-width:300px; width:100%; height:fit-content;" src="@/assets/img/kkk3.png">
              <img v-else-if="index == 4" style="max-width:300px; width:100%; height:fit-content;" src="@/assets/img/kkk4.png">
              <img v-else-if="index == 5" style="max-width:300px; width:100%; height:fit-content;" src="@/assets/img/kkk5.png">
              <img v-else-if="index == 6" style="max-width:300px; width:100%; height:fit-content;" src="@/assets/img/kkk6.png">
            </div>
            <div style="width:60%; padding:20px">
              <p style="padding:10px 0; font-weight: 600; font-size:16px; border-bottom:1px solid #d3d3d3;">攻略学院分享<span
                  @click="back" v-if="state.data.id" style="margin-right:10px; font-size:14px; float:right;">返回 ></span>
              </p>

              <template v-if="!state.data.id">
                <div @click="click(item)" v-for="(item, index) in state.list"
                  style="max-width:500px; position:relative; margin-top:10px; padding:10px; border:1px solid #fe813d; border-radius:5px;">
                  <p style="padding-bottom:5px; border-bottom:1px solid #999999; ">{{ '《 ' + item.post_title + ' 》' }}
                  </p>
                  <div style="margin-top:10px; display:flex; justify-content: left; align-items: center;">
                    <img style="width:20px; height:20px; " src="@/assets/img/logo.png">
                    <p style="margin-left:10px; ">{{
        item.post_source }}</p>
                    <p style="margin-left:10px; font-size:12px; width: 50px;
                        height: 20px;
                        line-height:20px;
                        text-align: center;
                        background-image: linear-gradient(-90deg, 
                          rgba(235, 195, 126, 1) 0%, 
                          #fadba6 100%);
                        border-radius: 8px;">官方
                    </p>
                  </div>

                  <!-- <img style="position:absolute; top:41px;"
                    :src="item.thumbnail_title ? item.thumbnail_title : '@/assets/img/logo.png'"> -->

                </div>
              </template>

              <template v-if="state.data.id">
                <div style="min-height: 400px;">
                  <p style="font-size:16px; font-weight:600; margin-top:20px;">{{ state.data.post_title }}</p>
                  <p style="margin-top:10px;">{{ state.data.post_source }}</p>
                  <div style="margin-top:10px;" v-html="state.data.post_content"></div>
                </div>
              </template>

            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>

  </div>
</template>

<script setup>

import { reactive, nextTick } from 'vue'

import axios from 'axios'

const caiList = [
  { id: 1026, name: '双色球' },
  { id: 1025, name: '大乐透' },
  { id: 301, name: '福彩3D' },
  { id: 302, name: '排列3' },
  { id: 303, name: '排列5' },
  { id: 2015, name: '快乐8' },
  { id: 2013, name: '七星彩' },
];

const state = reactive({
  page: 0,
  list: [],
  data: {},
})

const getGlList = () => {
  let p = { page: state.page };
  axios.get(window['url'] + 'portal/Articles/index', p).then(res => {
    console.log('res:', res.data);

    if (res.data.code == 1) {
      //请求成功
      state.list = res.data.data.data;
    }
  });
}

const back = () => {
  state.data = {};
}

const click = item => {
  axios.get(window['url'] + 'portal/Articles/read?id=' + item.id, null).then(res => {
    console.log('res:', res.data);

    if (res.data.code == 1) {
      //请求成功
      state.data = res.data.data;
    }
  });
}

nextTick(() => {
  getGlList();

})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
