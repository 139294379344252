<template>
  <div>
    <div style="display:flex; width:100%; height:160px; align-items: center; justify-content: center;">
      <div @click="download(0)" style="margin:10px; cursor:pointer;">
        <img style="position: relative; left:calc(50% - 30px); top:5px; width:60px; height:60px;"
          src="@/assets/img/pc.gif">
        <p class="dloadCls">电脑端下载</p>
      </div>
      <div @click="download(1)" style="margin:10px; cursor:pointer;">
        <img style="position: relative; left:calc(50% - 30px); top:5px; width:60px; height:60px;"
          src="@/assets/img/android.gif">
        <p class="dloadCls">Android下载</p>
      </div>
      <div @click="download(2)" style="margin:10px; cursor:pointer;">
        <img style="position: relative; left:calc(50% - 30px); top:5px; width:60px; height:60px;"
          src="@/assets/img/iOS.gif">
        <p class="dloadCls">IOS下载</p>
      </div>
    </div>

    <p style="margin-top:10px; color:#2f2f2f; text-align:center; font-size:20px; font-weight:600;">彩市最强大的彩票分析软件</p>
    <div style="color:#656565; text-align:center; font-size:14px; margin-top:20px; line-height:30px; padding: 0px 10%;">
      搜奇选妙是一款非购彩性质的内容服务产品，致力于为广大数字彩爱好者提供全方位
      的福彩、体彩数字彩玩法分析及参考的数据服务平台。</div>
    <div
      style="color:#ff634b; padding-top:15px; font-size: 12px; margin:0px 10%; border-top:1px solid #d4d4d4; text-align:center; margin-top:20px;">
      * 我们严格遵守相关法律法规，无任何形式的彩票销售行为 *</div>


    <div style="display:flex; width:100%; height:120px; align-items: center; justify-content: center;">
      <div @click="click(0)" style="margin:30px 5px 0px 5px;">
        <img class="imgFuncCls" src="@/assets/img/icon_xx.png">
        <p :style="state.funcSel == 0 ? 'color:#ff634b;' : ''" class="selCls">开奖信息</p>
        <div v-if="state.funcSel == 0" class="funcCls"></div>
      </div>
      <div @click="click(1)" style="margin:30px 5px 0px 5px;">
        <img class="imgFuncCls" src="@/assets/img/icon_ssgl.png">
        <p :style="state.funcSel == 1 ? 'color:#ff634b;' : ''" class="selCls">缩水过滤</p>
        <div v-if="state.funcSel == 1" class="funcCls"></div>
      </div>
      <div @click="click(2)" style="margin:30px 5px 0px 5px;">
        <img class="imgFuncCls" src="@/assets/img/icon_dj.png">
        <p :style="state.funcSel == 2 ? 'color:#ff634b;' : ''" class="selCls">大奖精选</p>
        <div v-if="state.funcSel == 2" class="funcCls"></div>
      </div>
      <div @click="click(3)" style="margin:30px 5px 0px 5px;">
        <img class="imgFuncCls" src="@/assets/img/icon_sqxm.png">
        <p :style="state.funcSel == 3 ? 'color:#ff634b;' : ''" class="selCls">搜奇选妙</p>
        <div v-if="state.funcSel == 3" class="funcCls"></div>
      </div>
      <div @click="click(4)" style="margin:30px 5px 0px 5px;">
        <img class="imgFuncCls" src="@/assets/img/icon_zj.png">
        <p :style="state.funcSel == 4 ? 'color:#ff634b;' : ''" class="selCls">专家推号</p>
        <div v-if="state.funcSel == 4" class="funcCls"></div>
      </div>
      <div @click="click(5)" style="margin:30px 5px 0px 5px;">
        <img class="imgFuncCls" src="@/assets/img/icon_gn.png">
        <p :style="state.funcSel == 5 ? 'color:#ff634b;' : ''" class="selCls">海量功能</p>
        <div v-if="state.funcSel == 5" class="funcCls"></div>
      </div>
    </div>

    <div
      style="border-top:1px solid #ddd; width:100%; height:400px; background: linear-gradient(180deg, #fff0f0, #ffffff);">
      <div style="position:relative; width:100%; height:100%;">
        <img style="position: absolute; left:calc(50% - 100px); top:calc(200px - 100px); width:200px; height:auto;"
          src="@/assets/img/bg.png">
        <img v-if="state.funcSel == 0"
          style="position: absolute; left:calc(50% - 60px); top:calc(200px - 110px); width:120px; height:auto;"
          src="@/assets/img/p0.png">
        <img v-else-if="state.funcSel == 1"
          style="position: absolute; left:calc(50% - 60px); top:calc(200px - 110px); width:120px; height:auto;"
          src="@/assets/img/p1.png">
        <img v-else-if="state.funcSel == 2"
          style="position: absolute; left:calc(50% - 60px); top:calc(200px - 110px); width:120px; height:auto;"
          src="@/assets/img/p2.png">
        <img v-else-if="state.funcSel == 3"
          style="position: absolute; left:calc(50% - 60px); top:calc(200px - 110px); width:120px; height:auto;"
          src="@/assets/img/p3.png">
        <img v-else-if="state.funcSel == 4"
          style="position: absolute; left:calc(50% - 60px); top:calc(200px - 110px); width:120px; height:auto;"
          src="@/assets/img/p4.png">
        <img v-else-if="state.funcSel == 5"
          style="position: absolute; left:calc(50% - 60px); top:calc(200px - 110px); width:120px; height:auto;"
          src="@/assets/img/p5.png">
      </div>
    </div>

    <div style="width:100%; height:150px; background-color:#FDDDDD">
      <div style="height:100%; display:flex; justify-content: center; ">
        <div
          style="color:#7f2929; height:100%; display:flex; justify-content: center; flex-direction: column; align-items: center;">
          <p
            style="font-size:12px; border-radius:5px; margin-top:0px; padding:10px 30px; background:#ffbead; width:180px;">
            中国大陆已禁止网络购彩，如有需求请前往福彩线下实体店。</p>
          <p
            style="font-size:12px; border-radius:5px; margin-top:10px; padding:10px 30px; background:#ffbead; width:180px;">
            官网域名：souqixuanmiao.com csbd8.com csbd6.com</p>
          <p style="font-size:10px; margin-top:10px; ">Copyright©2018-选妙科技 ALL Right Reserved</p>
        </div>
        <img style="margin-left:10px; margin-top:30px; width:80px; height:70px" src="@/assets/img/rz.png">
      </div>
    </div>

    <!-- <van-dialog :closeOnClickOverlay="true" v-model:show="state.download2Vis" title="IOS下载">
      <div style="display:flex; justify-content: center">
        <img style="width:80%;" src="@/assets/img/download.png">
      </div>
    </van-dialog>

    <van-dialog :closeOnClickOverlay="true" v-model:show="state.download1Vis" title="安卓下载">
      <div style="display:flex; justify-content: center">
        <img style="width:80%;" src="@/assets/img/download.png">
      </div>
    </van-dialog> -->

  </div>

</template>

<script setup>

import { reactive, nextTick } from 'vue'

import { showToast, showConfirmDialog } from 'vant';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import 'swiper/css/pagination';

const state = reactive({
  funcSel: 0,
})

const click = o => {
  state.funcSel = o;
}

const download = idx => {
  if (idx == 0) {
    showToast('PC下载包请在电脑端下载');
    // window.open('https://jhzjoss.oss-cn-shenzhen.aliyuncs.com/pc/%E8%AE%A1%E5%88%92%E4%B9%8B%E5%AE%B6.zip', '_blank');
  } else {

    window.open('http://www.csbd6.com', '_blank');
  }
}

nextTick(() => {

})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dloadCls {
  padding: 4px 5px;
  border: 1px solid #ff634b;
  text-align: center;
  margin-top: 20px;
  border-radius: 20px;
  color: #ff634b;
}

.selCls {
  padding: 4px 0px;
  text-align: center;
  margin-top: 15px;
  font-size: 12px;
}

.funcCls {
  position: relative;
  top: 11px;
  left: calc(50% - 6px);
  width: 6px;
  height: 6px;
  background: #fff;
  border: 3px solid #ff634b;
  border-radius: 10px;
}

.imgFuncCls {
  position: relative;
  left: calc(50% - 15px);
  top: 5px;
  width: 30px;
  height: 30px;
}
</style>
