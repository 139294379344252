<template>
  <!-- <swiper :effect="cube" :slidesPerView="3" :spaceBetween="30" :centeredSlides="true" :autoplay="false" :grabCursor="true" :loop="true"
    :autoplayStopOnLast="false" :navigation="true" :pagination="{ type: 'bullets', clickable: true }"
    :scrollbar="{ draggable: true }" :modules="state.modules" @swiper="onSwiper" @slideChange="onSlideChange"
    style="margin:10px 0"> -->
  <swiper class="swiperCls" :slidesPerView="auto" :spaceBetween="30" :centeredSlides="true" :autoplay="false" :grabCursor="true"
    :loop="true" :autoplayStopOnLast="false" :navigation="true"
    :pagination="{ type: 'bullets', clickable: true }"
    :scrollbar="{ draggable: true }" :modules="state.modules" @swiper="onSwiper" @slideChange="onSlideChange"
    style="margin:10px 0">
    <swiper-slide style="display:flex; justify-content: center; align-items: center;">
      <img style="height:auto; width:70%;" src="@/assets/img/banner1.png">
    </swiper-slide>
    <swiper-slide style="display:flex; justify-content: center; align-items: center;">
      <img style="height:auto; width:70%;" src="@/assets/img/banner2.png">
    </swiper-slide>
    <swiper-slide style="display:flex; justify-content: center; align-items: center;">
      <img style="height:auto; width:70%;" src="@/assets/img/banner3.png">
    </swiper-slide>
  </swiper>

</template>

<script setup>

import { reactive, nextTick } from 'vue'

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/less/navigation';
import 'swiper/less/pagination';
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
const state = reactive({
  modules: [Autoplay, Navigation, Pagination, Scrollbar, A11y],
})

nextTick(() => {
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
