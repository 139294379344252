import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/css/common.less'

import Vant from 'vant';
import 'vant/lib/index.css';

// window['url'] = 'http://192.168.50.69:7070/sex/api/';
window['url'] =  window.location.origin + '/sex/api/';

createApp(App).use(Vant).mount('#app')
