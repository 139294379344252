<template>
  <div>
    <Main v-if="!state.isMobile" msg="Welcome to Your Vue.js App" />
    <Main1 v-if="state.isMobile" msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script setup>
import Main from '@/Main.vue'
import Main1 from '@/Main1.vue'

import { reactive, nextTick } from 'vue'

const state = reactive({
  isMobile: false,
})

const getIsMobile = () => {
  let sUserAgent = navigator.userAgent.toLowerCase();

  // alert(sUserAgent);

  if (/ipad|iphone|macintosh/.test(sUserAgent)) {
    //跳转移动端页面
    // console.log("IOS");
    return 1;
  } else if (/android/.test(sUserAgent)) {
    // console.log("ANDROID");
    return 2;
  } else {
    //跳转pc端页面
    // console.log("PC");
    return false;
  }
}

nextTick(() => {
  state.isMobile = getIsMobile();
})
</script>

<style></style>